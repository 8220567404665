<template>
  <svg
    viewBox="0 0 48 48"
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="48"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M31.8182 26.25V20C31.8182 17.967 31.0112 16.003 29.5523 14.5441C28.0911 13.0829 26.0951 12.25 24 12.25C21.9049 12.25 19.9089 13.0829 18.4477 14.5441C16.9888 16.003 16.1818 17.967 16.1818 20V26.25H11C9.70051 26.25 8.4676 26.7669 7.56902 27.6654C6.67274 28.5617 6.18182 29.7626 6.18182 31C6.18182 32.2374 6.67274 33.4383 7.56902 34.3346C8.4676 35.2331 9.70051 35.75 11 35.75H37C38.2995 35.75 39.5324 35.2331 40.431 34.3346C41.3273 33.4383 41.8182 32.2374 41.8182 31C41.8182 29.7626 41.3273 28.5617 40.431 27.6654C39.5324 26.7669 38.2995 26.25 37 26.25H31.8182ZM12 20V22H11C8.61305 22 6.32387 22.9482 4.63604 24.636C2.94821 26.3239 2 28.6131 2 31C2 33.387 2.94821 35.6761 4.63604 37.364C6.32387 39.0518 8.61305 40 11 40H37C39.3869 40 41.6761 39.0518 43.364 37.364C45.0518 35.6761 46 33.387 46 31C46 28.6131 45.0518 26.3239 43.364 24.636C41.6761 22.9482 39.3869 22 37 22H36V20C36 16.8174 34.7357 13.7652 32.4853 11.5147C30.2348 9.26428 27.1826 8 24 8C20.8174 8 17.7652 9.26428 15.5147 11.5147C13.2643 13.7652 12 16.8174 12 20Z"
    />
  </svg>
</template>
